import {SoundElements} from "./interfaces/SoundData";
import {useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";

interface Props {
    file: SoundElements;
}

export default function SoundFile({file}: Props) {
    const [showAudio, setShowAudio] = useState<boolean>(false);
    const audio = useRef<HTMLAudioElement>(null);
    useEffect(() => {
        const audioElement: HTMLAudioElement|null = audio.current;
        if (audioElement != null) {
            audioElement.volume = file.volume;
            audioElement.playbackRate = file.pitch;
            audioElement.play().then(null, null);
        }
    });
    return (
        <tr>
            <td>
                <h4>{file.fileName}</h4>
                {!showAudio && (
                    <Button variant="light" onClick={() => setShowAudio(true)}>Play</Button>
                )}
                {showAudio && (
                    <audio controls={true} ref={audio}>
                        <source src={file.url} type="audio/ogg" />
                        Your browser does not support the audio element.
                    </audio>
                )}
            </td>
            <td>
                <h4>{file.duration/1000}s</h4>
            </td>
        </tr>
    );
}