import {Sound} from "./interfaces/SoundData";
import SoundFile from "./SoundFile";
import {Table} from "react-bootstrap";
import {from} from "linq-to-typescript";

interface Props {
    sound: Sound;
}

export default function SoundBox({sound}: Props) {
    //const [defaultPlay, setDefaultPlay] = useState<boolean>(true);
    return (
        <div className="col-6">
            <Table striped bordered hover variant="dark">
                <thead>
                <tr>
                    <th>
                        <h3>
                            {sound.key}
{/*                            <Button variant="light" onClick={() => setDefaultPlay(true)}>Play</Button>*/}
                        </h3>
                    </th>
                    <th>
                        <h3>Added: {from(sound.versions).last()}</h3>
                    </th>
                </tr>
                </thead>
                <tbody>
                {sound.sounds.map((file, i) => {
                    return (<SoundFile key={i} file={file} />)
                })}
                </tbody>
            </Table>
        </div>
    );
}
