import React from 'react';
import datenJson from './bunucokove.json';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {SoundData} from "./interfaces/SoundData";
import {from} from "linq-to-typescript";
import SoundFolder from "./SoundFolder";
import {Accordion} from "react-bootstrap";
const daten: SoundData = datenJson;


function App() {
  return (
      <div className="App" style={{ margin: "30px" }}>
          <h1 style={{textAlign: "center"}}>Datenstand: AssertId: {daten.assertId} - Minecraft Version: {daten.minecraftVersion}</h1>
          <div className="row">
              <div className="col-12">
                  <Accordion>
                      {from(daten.sounds).groupBy((x) => x.key.split(".")[0]).toArray().map((group, i) => {
                          return (<SoundFolder key={i} index={i.toString()} name={group.key} sounds={group.toArray()} />)
                      })}
                  </Accordion>
              </div>
          </div>
      </div>
/*    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>*/
  );
}

export default App;
