import {Sound} from "./interfaces/SoundData";
import SoundBox from "./SoundBox";
import React from "react";
import {Accordion, Button, Card} from "react-bootstrap";

interface Props {
    index: string;
    name: string;
    sounds: Sound[];
}

export default function SoundFolder({index, name, sounds}: Props) {
    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                    {name}
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index}>
                <Card.Body>
                    <div className="row">
                        {sounds.map((sound, i) => {
                            return (<SoundBox key={i} sound={sound} />)
                        })}
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}
